import { useState, useEffect, useCallback } from "react";
import { Theme, AutoTheme } from "types/view";

/** This hook returns the theme that should be used, overriding system preferences to always use dark mode. */
export const useAutoTheme = (theme: Theme): AutoTheme => {
  const getInitialAutoTheme = useCallback((): AutoTheme => {
    // Ignore the actual theme passed and always use "dark"
    return "dark";
  }, []);

  const [autoTheme, setAutoTheme] = useState<AutoTheme>(getInitialAutoTheme());

  // Update the theme. Always use "dark", ignoring system preference and other theme values.
  useEffect(() => {
    const handleColorSchemeChange = (e: MediaQueryListEvent) => {
      // This callback is now redundant since we always use dark mode
      // but we'll keep it for consistency.
      setAutoTheme("dark");
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleColorSchemeChange);

    // Force dark mode by setting autoTheme to "dark"
    setAutoTheme("dark");

    return () => {
      // Cleanup the event listener
      mediaQuery.removeEventListener("change", handleColorSchemeChange);
    };
  }, []);

  return autoTheme;
};
